import brand from "./brand.svg";
import leaf from "./leaf.svg";
import pack from "./package.svg";
import price from "./price.svg";
import shop from "./shop.svg";
import world from "./world.svg";

export const Icons = [
  {
    img: brand,
    head: "Make Your Own Brand ",
    desc: "Join us to create your own brand—innovative, unique, and tailored to stand out. Let’s shape your vision into success together",
  },
  {
    img: leaf,
    head: "100% Natural Organic",
    desc: "Experience purity with our 100% Natural Organic products. Embrace a healthier lifestyle with ingredients that are truly natural and sustainably sourced",
  },
  {
    img: pack,
    head: "Healthy & Safe Packing",
    desc: "Enjoy peace of mind with our 100% healthy and safe packaging. Protecting both you and the environment with every product.",
  },
];
export const Icons2 = [
  {
    img: price,
    head: "Best at Low Cost",
    desc: "Get the best quality at the lowest cost. Affordable excellence that meets your needs without compromising on value or performance",
  },
  {
    img: shop,
    head: "Home-made Production",
    desc: "Experience the essence of tradition with our homemade production of tea and spices. Handcrafted for unmatched freshness and authentic flavor.",
  },
  {
    img: world,
    head: "World-Wide Delivery",
    desc: "Enjoy global convenience with our worldwide delivery service. Freshly crafted tea and spices, delivered right to your doorstep, wherever you are.",
  },
];
