import { WhyChooseUs } from "./WhyChooseUs";
import { Carousel } from "./Carousel ";
import { NewArrivals } from "./Products";
import { About } from "./AboutUs";
import { ContactUs } from "./ContactUs";

export const Home = () => {
  return (
    <>
      <Carousel />
      <About />
      <NewArrivals />
      <WhyChooseUs />
      <ContactUs />
    </>
  );
};
