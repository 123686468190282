import { useState } from "react";
import "./ContactUs.css";

export const ContactUs = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c8974a69-ae89-4bbd-8c71-9aeb729d9821");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      console.log(data.message);
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <>
      <div className="container" id="#contact">
        <div className="row row-cols-md-2 row-cols-1">
          <div className="col">
            <iframe
              title="map"
              width="400px"
              height="300px"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.3356868930005!2d-1.4776540240592249!3d52.41871504372439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877495f9d9e4cb9%3A0xa85a23fd99112306!2s88%20Alfall%20Rd%2C%20Coventry%2C%20UK!5e0!3m2!1sen!2slk!4v1725723865124!5m2!1sen!2slk"
              loading="lazy"
            ></iframe>
          </div>
          <div className="col contact-info text-start">
            <h2>Contact</h2>
            <div className="row gap-4 pb-3 mt-4">
              <div className="col">Phone No:</div>
              <div className="col">
                <a href="tel:+447380457655">07380457655</a>
              </div>
              <div className="row  text-start">
                <div className="col">Address:</div>
                <div className="col">
                  88 Alfall Road Coventry Midland CV2 3GG UK
                </div>
              </div>
              <div className="row  text-start">
                <div className="col">E-mail:</div>
                <div className="col">
                  <a href="mailto:info@realteaandspices.com ">
                    info@realteaandspices.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Contact US Form */}
      </div>

      <div className="container form">
        <form onSubmit={onSubmit}>
          <div className="row row-cols-2">
            <div className="col">Name:</div>
            <div className="col">
              <input type="text" name="name" required />
            </div>
            <div className="col">E-mail:</div>
            <div className="col">
              <input type="email" name="email" required />
            </div>
            <div className="col">Message:</div>
            <div className="col">
              <textarea rows={5} name="message" required></textarea>
            </div>
          </div>

          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </form>
        <span>{result}</span>
      </div>
    </>
  );
};
