import "./WhyChooseUs.css";
import about from "../Images/Images/pngwing.com (2).png";
import { Icons, Icons2 } from "../Images/Icons/Icons";

export const WhyChooseUs = () => {
  const icons = Icons;
  const icons2 = Icons2;

  const desc1 = icons.map((icons, index) => (
    <div className="row" key={index}>
      <div className="col desc-bg">
        <div className="col">
          <img className="desc-img" src={icons.img} alt="real tea and spice" />
        </div>
        <div className="col">
          <h6>{icons.head}</h6>
          <p className="desc">{icons.desc}</p>
        </div>
      </div>
    </div>
  ));

  const desc2 = icons2.map((icons2, index) => (
    <div className="row " key={index}>
      <div className="col desc-bg">
        <div className="col">
          <img className="desc-img" src={icons2.img} alt="real tea and spice" />
        </div>
        <div className="col">
          <h6>{icons2.head}</h6>
          <p className="desc">{icons2.desc}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div id="about">
      <div className="container">
        <h2>Why Choose Us</h2>
        <div className="row row-cols-1 row-cols-md-3">
          <div className="col">{desc1}</div>
          <div className="col align-self-center image">
            <img className="about-img" src={about} alt="real tea and spice" />
          </div>
          <div className="col ">{desc2}</div>
        </div>
      </div>
    </div>
  );
};
