import about from "../Images/Images/About us.jpg";
import "./About.css";

export const About = () => {
  return (
    <div className="About">
      <div className="container">
        <h2>About Us</h2>
        <div className="row">
          <div class="align-self-center">
            <img
              className=" About-img img-fluid"
              src={about}
              alt="real tea and spice"
            />
          </div>
        </div>
        <div className="row">
          <p>
            Founded in 2020 Real Tea & Spice Company is a leading supplier of
            premium teas and exotic spices. Our commitment to quality and
            passion for flavor excellence have made us a trusted partner for
            businesses around the world. We cater to tea shops, gourmet stores,
            restaurants, and food manufacturers, providing them with the finest
            ingredients to create their unique products.
          </p>
          <p>
            Our mission is simple: to source, curate, and supply an exceptional
            range of teas and spices that inspire our customers. We strive to
            bring the world’s best flavors right to your doorstep while
            maintaining the highest standards of quality, sustainability, and
            ethical sourcing.
          </p>
          <p>
            We believe in responsible sourcing. Our teas and spices are procured
            from reputable growers who practice sustainable farming and fair
            trade. This ensures that we not only deliver top-quality products
            but also contribute positively to the communities
            where they are grown.
          </p>
        </div>
      </div>
    </div>
  );
};
