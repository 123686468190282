import { Link } from "react-router-dom";
import "./NotFound.css";

export const NotFound = () => {
  return (
    <div className="Error">
      <div className="col">
        <h3>Page Not Found</h3>
        <Link to={"/"}>Home</Link>
      </div>
    </div>
  );
};
