import c4 from "../Images/Images/c1.jpg";
import c1 from "../Images/Images/c2.jpg";
import c3 from "../Images/Images/c3.jpg";
import c2 from "../Images/Images/c4.jpg";
import "./Carousel.css";
export const Carousel = () => {
  return (
    <div id="carouselExampleIndicators" className="carousel slide pt-2">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={c1}
            className="img-fluid d-block w-100"
            alt="Real tea and Spice"
          />
        </div>
        <div className="carousel-item">
          <img
            src={c2}
            className="img-fluid d-block w-100"
            alt="Real tea and Spice"
          />
        </div>
        <div className="carousel-item">
          <img
            src={c3}
            className="img-fluid d-block w-100"
            alt="Real tea and Spice"
          />
        </div>
        <div className="carousel-item">
          <img
            src={c4}
            className="img-fluid d-block w-100"
            alt="Real tea and Spice"
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
