import { useState } from "react";
import { Tea } from "../Images/Images/Tea/Tea";
import "./Products.css";
import { Link, useNavigate } from "react-router-dom";
import { Spice } from "../Images/Images/Spices/Spices";

/*Product Detail Page*/
export const ProductDetail = () => {
  return (
    <div className="productDetail">
      <div className="container">
        <div className="row">
          <div className="col">
            <img src={setProduct.img} alt={setProduct.name} />
          </div>
          <div className="col">
            <h2>{setProduct.name}</h2>
            <p className="pt-4">
              Grab the perfect blend of over 5 different spices needed to create
              the delicious Indian or southeast Asian meal, Tandoori Chicken.
              You can also use the same blend to make the perfect Tikka Masala
              and wow your family. Just add some yogurt to our best-selling
              spice blend and create the mouth-watering marinade Tandoori
              chicken or Tikka Masala today.
            </p>
            <p className="mt-4 text-start price">
              &#163;{setProduct.price}.00 <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export let setProduct;

export const Products = () => {
  const [choice, setChoice] = useState(1);
  const navigate = useNavigate();
  const [selection, setSelection] = useState("Tea");
  /*Spice Mapping*/
  const spice = Spice.map((Spice, index) => (
    <div key={index} className="col p-3">
      <div
        className="card"
        onClick={() => {
          setProduct = Spice;
          navigate("/detail");
        }}
      >
        <img src={Spice.img} className="img-fluid" alt="real tea and spice" />
        <div className="card-body">
          <h5 className="card-title">{Spice.name}</h5>
          <p className="text-start">&#163;{Spice.price}.00</p>
        </div>
      </div>
    </div>
  ));

  /*tea mapping*/
  const tea = Tea.map((Tea, index) => (
    <div key={index} className="col p-3">
      <div
        className="card"
        onClick={() => {
          setProduct = Tea;
          navigate("/detail");
        }}
      >
        <img src={Tea.img} className="img-fluid" alt="real tea and spice" />
        <div className="card-body">
          <h5 className="card-title">{Tea.name}</h5>
          <p className="text-start">&#163;{Tea.price}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container">
      <h3>
        Products:{" "}
        <div className="dropdown mb-3">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selection}
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                onClick={() => {
                  setChoice(1);
                  setSelection("Tea");
                }}
                className="dropdown-item"
              >
                Tea
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setChoice(0);
                  setSelection("Spice");
                }}
                className="dropdown-item"
              >
                Spices
              </Link>
            </li>
          </ul>
        </div>
      </h3>
      <div className="Products row row-cols-md-4 row-cols-2 ">
        {choice ? tea : spice}
      </div>
    </div>
  );
};

/*New Arrivals*/

export const NewArrivals = () => {
  const navigate = useNavigate();
  const arr = [Spice[2], Spice[5], Tea[3], Tea[1]];
  const card = arr.map((arr, index) => (
    <div key={index} className="col p-3">
      <div
        className="card"
        onClick={() => {
          setProduct = arr;
          navigate("/detail");
        }}
      >
        <img src={arr.img} className="img-fluid" alt="real tea and spice" />
        <div className="card-body">
          <h5
            style={{
              fontSize: 20,
            }}
            className="card-title"
          >
            {arr.name}
          </h5>
          <p className="text-start">&#163;{arr.price}</p>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="container">
      <h2>New Arrivals</h2>
      <div className="row row-cols-md-4 row-cols-2">{card}</div>
    </div>
  );
};
