import BlackTea from "./Black Tea.jpg";
import BOP from "./BOP Tea.jpg";
import GoldenTips from "./Golden Tips Tea.jpg";
import Silvertips from "./SIlver Tips Tea.jpg";

export const Tea = [
  {
    img: BlackTea,
    price: 9.99,
    name: "Black Tea",
  },
  {
    img: BOP,
    name: "Broken Orange Pekoe",
    price: 14.99,
  },
  {
    img: GoldenTips,
    price: 8.99,
    name: "Golden Tips",
  },
  {
    img: Silvertips,
    price: 13.99,
    name: "Silver Tips",
  },
];
