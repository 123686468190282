import logo from "../Images/Logo/RealTeaandSpiceLogo.png";
import { NavLink } from "react-router-dom";
import "./Nav.css";
import menu from "../Images/Icons/menu.svg";
import close from "../Images/Icons/close.svg";

export const Nav = () => {
  return (
    <nav>
      <NavLink to="/">
        <img id="logo" src={logo} alt="logo" />
      </NavLink>
      <input type="checkbox" id="sidebar-active" />
      <label htmlFor="sidebar-active" className="open-sidebar-button">
        <img className="icons" src={menu} alt="real tea and spice" />
      </label>
      <label htmlFor="sidebar-active" id="overlay"></label>
      <div className="links-container">
        <label htmlFor="sidebar-active" className="close-sidebar-button">
          <img className="icons" src={close} alt="real tea and spice" />
        </label>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/products">Products</NavLink>
      </div>
    </nav>
  );
};
