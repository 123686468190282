import BlackPapper from "./Black Pepper.jpg";
import Cardamom from "./Cardamom.jpg";
import CinnamonStick from "./Cinnamon.jpg";
import Cloves from "./Cloves.jpg";
import CorianderSeeds from "./Coriander.jpg";
import CuminSeeds from "./Cumin.jpg";
import DriedChilli from "./Chilli Powder.jpg";
import FeenelSeeds from "./Fennel.jpg";
import FenugreekSeeds from "./Fenugreek.jpg";
import Ginger from "./Ginger.jpg";
import JaffnaCurryPowder from "./JaffnaCurryPowder.jpg";
import Mace from "./Mace.jpg";
import MustardSeeds from "./Mustard.jpg";
import Nutmeg from "./Nutmeg.jpg";
import StarAnise from "./StarAnise.jpg";
import Turmeric from "./Turmeric.jpg";
import vanillaStick from "./⁠Vanilla Stick.jpg";

export const Spice = [
  {
    img: BlackPapper,
    price: 7.99,
    name: "Black Pepper",
  },
  {
    img: Cardamom,
    price: 8.99,
    name: "Cardamom",
  },
  {
    img: CinnamonStick,
    price: 11.99,
    name: "Cinnamon Stick",
  },
  {
    img: Cloves,
    price: 11.99,
    name: "Cloves",
  },
  {
    img: CorianderSeeds,
    price: 12.99,
    name: "Coriander Seeds",
  },
  {
    img: CuminSeeds,
    price: 10.99,
    name: "Cumin Seeds",
  },
  {
    img: DriedChilli,
    price: 4.99,
    name: "Dried Chilli",
  },
  {
    img: FeenelSeeds,
    price: 11.99,
    name: "Feenel Seeds",
  },
  {
    img: FenugreekSeeds,
    price: 12.99,
    name: "Fenugreek Seeds",
  },
  {
    img: Ginger,
    price: 7.99,
    name: "Ginger",
  },
  {
    img: JaffnaCurryPowder,
    price: 5.99,
    name: "Jaffna Curry Powder",
  },
  {
    img: Mace,
    price: 4.99,
    name: "Mace",
  },
  {
    img: MustardSeeds,
    price: 6.99,
    name: "Mustard Seeds",
  },
  {
    img: Nutmeg,
    price: 4.99,
    name: "Nutmeg",
  },
  {
    img: StarAnise,
    price: 6.99,
    name: "Star Anise",
  },
  {
    img: Turmeric,
    price: 9.99,
    name: "Turmeric",
  },
  {
    img: vanillaStick,
    price: 12.99,
    name: "Vanilla Stick",
  },
];
