import "./Footer.css";

export const Footer = () => {
  const date = new Date();
  const currentDate = date.getFullYear();

  return (
    <div className="Footer">
      <div className="row ">
        <p>Real Tea and Spices Limited @ {currentDate}</p>
      </div>
    </div>
  );
};
